import { Route, BrowserRouter, Routes } from 'react-router-dom';
import Login from './pages/v2/Login';
import Logout from './pages/v2/Logout';
import LoginV3 from './pages/v3/Login';
import LogoutV3 from './pages/v3/Logout';
import Pin from './pages/v3/PIN';
import RedirectLoggedInUser from './pages/v3';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import useDeviceId from './pages/v3/hooks/useDeviceId';
import Analytics from './Analytics';

const App = () => {
  const queryClient = new QueryClient();
  const deviceId = useDeviceId();

  // @ts-ignore
  pendo.initialize({
    visitor: {
      id: deviceId,
    },
  });

  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <Analytics />
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/v2" element={<Login />} />

          <Route path="/logout" element={<Logout />} />
          <Route path="/v2/logout" element={<Logout />} />

          <Route path="/v3" element={<RedirectLoggedInUser />} />
          <Route path="/v3/pin" element={<Pin />} />
          <Route path="/v3/login" element={<LoginV3 />} />
          <Route path="/v3/logout" element={<LogoutV3 />} />
        </Routes>
      </BrowserRouter>
    </QueryClientProvider>
  );
};

export default App;
