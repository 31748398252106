import { useEffect } from 'react';
import { useLocation } from 'react-router';
import { Userpilot } from 'userpilot';
import config from './config';
import useDeviceId from './pages/v3/hooks/useDeviceId';
import useLocationName from './pages/v3/hooks/useLocationName';

const Analytics = () => {
  const location = useLocation();
  const deviceId = useDeviceId();
  const urlParams = new URLSearchParams(window.location.search);
  const locationIdString =
    urlParams.get('location_id') || localStorage.getItem('location_id');
  const locationId = locationIdString ? parseInt(locationIdString) : undefined;
  const locationName: string | undefined = locationId
    ? useLocationName(locationId).data
    : undefined;

  const userpilotToken =
    config.env === 'prod' ? 'NX-f4b682c9' : 'STG-NX-f4b682c9';
  Userpilot.initialize(userpilotToken);
  Userpilot.identify(deviceId, {
    company: {
      locationId: locationId,
      name: locationName,
    },
  });

  useEffect(() => {
    Userpilot.reload();
  }, [location]);

  return null;
};

export default Analytics;
